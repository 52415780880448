<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="assignOne-container"
  >
    <assign-step :stepActive="1"></assign-step>
    <!-- <div class="common-grey-line"></div> -->
    <el-form
      ref="formShopList"
      :model="shopForm"
      label-width="170px"
      class="demo-ruleForm"
    >
      <div
        class="shop-item"
        v-for="(item, index) in shopForm.detailList"
        :key="index"
      >
        <div class="common-grey-line"></div>
        <div class="shopItem-form">
          <div class="leftRight">
            <el-form-item label="店铺logo：">
              <img
                class="common-iconImg-MR"
                v-if="item.shopLogo"
                :src="item.shopLogo"
                alt=""
              />
              <i v-else class="el-icon-user common-iconImg-MR"></i>
            </el-form-item>
            <el-form-item
              label="店铺名称："
              :prop="`detailList[${index}].shopName`"
              :rules="{
                required: true,
                message: '请选择店铺',
                trigger: 'change'
              }"
            >
              <el-input
                class="common-screen-input_50"
                @change="
                  () => {
                    assignShopSelect(item.shopName, index);
                  }
                "
                placeholder="请选择店铺"
                v-model="item.shopName"
              ></el-input>
              <el-button
                class="common-screen-btn"
                @click="
                  () => {
                    assignShopSelect(item.shopName, index);
                  }
                "
                type="primary"
                >查 询</el-button
              >
              <el-button
                class="common-screen-btn clear"
                @click="
                  () => {
                    resetShop(index);
                  }
                "
                plain
                >重 置</el-button
              >
            </el-form-item>
            <el-form-item label="店铺所在平台：">
              <el-input
                class="common-screen-input_100"
                placeholder="请选择店铺"
                v-model="item.platformName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="类目：">
              <el-input
                class="common-screen-input_100"
                placeholder="请选择店铺"
                v-model="item.categoryName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="客户名称：">
              <el-input
                class="common-screen-input_100"
                placeholder="请选择店铺"
                v-model="item.customerName"
                disabled
              ></el-input>
            </el-form-item>
          </div>
          <div class="leftRight">
            <el-form-item
              label="服务时间范围："
              :prop="`detailList[${index}].serviceDate`"
              :rules="{
                required: true,
                message: '请选择服务时间',
                trigger: 'change'
              }"
            >
              <el-date-picker
                v-model="item.serviceDate"
                @change="
                  (val) => {
                    serviceDateFun(val, index);
                  }
                "
                type="daterange"
                range-separator="~"
                :clearable="false"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
              <el-popconfirm
                title="确定删除吗？"
                @confirm="
                  () => {
                    deleteShopItem(index);
                  }
                "
              >
                <el-button
                  slot="reference"
                  class="common-screen-btn delete-btn"
                  plain
                  >删 除</el-button
                >
              </el-popconfirm>
            </el-form-item>
            <el-form-item
              label="店铺服务天数："
              :rules="{
                required: true,
                message: '请选择服务时间',
                trigger: 'change'
              }"
            >
              <el-input
                class="input-width"
                v-model="item.shopServiceDay"
                disabled
              ></el-input>
              <span>天 （ 根据服务时间范围自动结算 ）</span>
            </el-form-item>
            <el-form-item
              label="服务内容："
              :prop="`detailList[${index}].checkbox`"
              :rules="{
                required: true,
                message: '请选择服务内容',
                trigger: 'change'
              }"
            >
              <el-checkbox-group
                v-model="item.checkbox"
                @change="
                  (val) => {
                    contentFun(val, index);
                  }
                "
              >
                <el-checkbox label="1">售前</el-checkbox>
                <el-checkbox label="2">售中</el-checkbox>
                <el-checkbox label="3">售后</el-checkbox>
                <el-checkbox label="4">附加类</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="协议客服工作时间："
              :prop="`detailList[${index}].agree`"
              :rules="{
                required: true,
                message: '请选择工作时间',
                trigger: 'change'
              }"
            >
              <el-time-picker
                is-range
                v-model="item.agree"
                @change="
                  (val) => {
                    workTimeFun(val, index);
                  }
                "
                value-format="HH:mm:ss"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
              ></el-time-picker>
              <!-- <span v-if="index == 0" class="el-icon-circle-plus-outline" @click="() => { addPhoneFun() }"></span>
                            <span v-if="index != 0" class="el-icon-delete" @click="() => { deletePhoneFun(index) }"></span> -->
            </el-form-item>
            <el-form-item
              label="客服工作时长："
              :rules="{
                required: true,
                message: '请选择工作时间',
                trigger: 'change'
              }"
            >
              <el-input
                class="input-width"
                v-model="item.serviceWorkTime"
                disabled
              ></el-input>
              <span>小时</span>
            </el-form-item>
          </div>
        </div>
        <div class="shopItem-form">
          <div class="leftRight" style="margin:0;width:100%;">
            <el-form-item label="协议子账号管理：">
              <el-button
                class="common-screen-btn"
                type="primary"
                @click="
                  () => {
                    addSub(item.shopId, index);
                  }
                "
                >新建子账号</el-button
              >
            </el-form-item>
            <el-form-item>
              <div class="sub-List">
                <div class="subList-header">
                  <div class="table-fontsize" style="width: 80px"></div>
                  <div class="table-fontsize" style="width: 180px">
                    子账号名称
                  </div>
                  <div class="table-fontsize">子账号密码</div>
                  <div class="table-fontsize">管理员手机号</div>
                  <div class="table-fontsize">指派客服</div>
                  <div class="table-fontsize">服务时间</div>
                  <div class="table-fontsize">备注</div>
                  <div class="table-fontsize">创建人</div>
                  <div class="table-fontsize">创建时间</div>
                </div>
                <div
                  v-if="item.subList && item.subList.length"
                  class="subList-content"
                >
                  <div
                    class="subList-item"
                    v-for="(itemTwo, indexTwo) in item.subList"
                    :key="indexTwo"
                  >
                    <div
                      class="table-fontsize"
                      style="width: 80px;text-indent: 8px"
                    >
                      <el-checkbox
                        :disabled="itemTwo.isReception == '1' ? true : false"
                        @change="
                          () => {
                            chooseSubFun(index);
                          }
                        "
                        v-model="itemTwo.isChecked"
                      ></el-checkbox>
                    </div>
                    <div class="table-fontsize" style="width: 180px">
                      {{ itemTwo.subAccountName || "--" }}
                    </div>
                    <div class="table-fontsize">
                      {{ itemTwo.subAccountPassword || "--" }}
                    </div>
                    <div class="table-fontsize">
                      {{ itemTwo.phone || "--" }}
                    </div>
                    <div class="table-fontsize">
                      {{ itemTwo.nickName || "--" }}
                    </div>
                    <div class="table-fontsize isWarp">
                      {{ itemTwo.startTime }}{{ "\n" }}{{ itemTwo.endTime }}
                    </div>

                    <!-- <el-tooltip v-if="itemTwo.note" class="item" effect="dark" :content="itemTwo.note" placement="bottom">
                                            <div class="table-fontsize">{{itemTwo.startTime}}{{'\n'}}{{itemTwo.endTime}}</div> 
                                        </el-tooltip> -->
                    <el-tooltip
                      v-if="itemTwo.note"
                      class="item"
                      effect="dark"
                      :content="itemTwo.note"
                      placement="bottom"
                    >
                      <div class="table-fontsize">{{ itemTwo.note }}</div>
                    </el-tooltip>
                    <div v-else class="table-fontsize">--</div>

                    <div class="table-fontsize">
                      {{ itemTwo.createName || "--" }}
                    </div>
                    <div class="table-fontsize">
                      {{ itemTwo.createTime || "--" }}
                    </div>
                  </div>
                </div>
                <div v-else class="subList-content">
                  <div style="text-align: center">暂无数据</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="协议子账号数量：">
              <el-input
                class="input-width"
                v-model="item.agreeSubNum"
                disabled
              ></el-input>
              <span> 个 （ 根据子账号管理自动结算 ）</span>
            </el-form-item>
            <el-form-item
              label="派单金额"
              :prop="`detailList[${index}].price`"
              :rules="{
                required: true,
                message: '请填写金额',
                trigger: 'change'
              }"
            >
              <el-input
                class="price-input-width"
                v-model="item.price"
              ></el-input>
              <span> 元</span>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <div class="common-grey-line"></div>
    <div class="addShop">
      该订单还可添加更多店铺（
      <span
        @click="
          () => {
            addShopItem();
          }
        "
        >添加店铺</span
      >
      ）
    </div>
    <div class="footer-container">
      <div class="common-grey-line"></div>
      <div class="footer-content">
        <div>
          <el-button class="back-btn" plain>返回派单中心</el-button>
          <el-button
            class="next-btn"
            type="primary"
            @click="
              () => {
                saveAssignOrder('formShopList');
              }
            "
            >下一步：供应商信息填报</el-button
          >
        </div>
        <div>
          <el-button
            class="next-btn"
            plain
            @click="
              () => {
                shopForm = { detailList: [{ shopName: '', checkbox: [] }] };
              }
            "
            >重置此页</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      v-dialogDrag
      title="店铺"
      :append-to-body="true"
      :visible.sync="shopVisible"
      width="620px"
    >
      <el-table
        class="common-table"
        :data="shopTableData"
        @row-click="
          (row) => {
            chooseShop(row);
          }
        "
      >
        <el-table-column
          prop="shopName"
          label="店铺名称"
          width="180px"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="platformName"
          label="所属平台"
          width="120px"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="categoryName"
          label="所属类目"
          width="120px"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="customerName"
          label="客户名称"
          width="140px"
          :formatter="tableColumn"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="新建子账号"
      :append-to-body="true"
      :visible.sync="subVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="subRuleForm"
          :rules="subRules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="子账号名称" prop="subaccountName">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号名称"
              v-model="subRuleForm.subaccountName"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="子账号密码" prop="subaccountPassword">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号密码"
              v-model="subRuleForm.subaccountPassword"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员手机号" prop="phone">
            <el-input
              class="dialog-input"
              placeholder="请填写管理员手机号"
              v-model="subRuleForm.phone"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              v-model="subRuleForm.datePicker"
              class="dialog-input"
              type="daterange"
              :clearable="false"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              class="dialog-input"
              placeholder="请填写备注"
              v-model="subRuleForm.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="subVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AssignStep from "./children/AssignStep.vue";
import {
  assignShopSelect,
  assignShopSub,
  saveAssignOrder,
  assignOrderDetail
} from "../../service/assignOrder.js";
import { addEditSubaccount } from "../../service/customer.js";
import {
  tableColumn,
  RangeDateNum,
  DateTransform,
  RangeTimeNum
} from "../../utils/index.js";
export default {
  components: {
    AssignStep
  },
  data() {
    return {
      loading: false,
      tableColumn,
      subVisible: false,
      shopVisible: false,
      shopTableData: [],
      shopForm: { detailList: [{ shopName: "", checkbox: [] }] },
      editShopIndex: 0,
      subRuleForm: {},
      subRules: {
        subaccountName: [
          { required: true, message: "请填写子账号名称", trigger: "change" }
        ],
        subaccountPassword: [
          { required: true, message: "请填写子账号密码", trigger: "change" }
        ],
        datePicker: [
          { required: true, message: "请选择服务时间", trigger: "change" }
        ]
      }
    };
  },
  created() {
    if (this.$route.query.id) {
      this.assignOrderDetail();
    }
  },
  methods: {
    submitForm(formName) {
      // 新增子账号提交
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let subRuleForm = { ...this.subRuleForm };
          if (subRuleForm.datePicker && subRuleForm.datePicker.length) {
            subRuleForm.startTime = DateTransform(subRuleForm.datePicker[0]);
            subRuleForm.endTime = DateTransform(subRuleForm.datePicker[1]);
            delete subRuleForm.datePicker;
          }
          this.loading = true;
          let { code } = await addEditSubaccount(subRuleForm);
          this.loading = false;
          if (code == 200) {
            this.$message.success("添加成功");
            this.subLoad(subRuleForm.shopId);
            this.subVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    addSub(shopId, index) {
      // 新增弹窗
      if (!shopId) {
        return this.$message.error("请先选择店铺");
      }
      this.editShopIndex = index;
      this.subRuleForm = {
        shopId: shopId,
        datePicker: []
      };
      if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      this.subVisible = true;
    },
    async assignOrderDetail() {
      // 返回编辑获取详情
      let { data } = await assignOrderDetail({
        disOrderNo: this.$route.query.id
      });
      if (data) {
        let newShopList = [...data.shopList];
        if (newShopList && newShopList.length) {
          newShopList.map((item) => {
            item.serviceDate = [
              DateTransform(item.startTime),
              DateTransform(item.endTime)
            ];
            item.agree = [item.agreeStartTime, item.agreeEndTime];
            item.checkbox = item.serviceContent.split(",") || [];
            assignShopSub({ shopId: item.shopId }).then((res) => {
              let subData = res.data;
              if (subData.subList && subData.subList.length) {
                subData.subList.map((itemTwo) => {
                  itemTwo.isChecked = false;
                  if (item.subAccountList && item.subAccountList.length) {
                    item.subAccountList.map((itemThree) => {
                      if (itemTwo.subAccountId == itemThree.subAccountId) {
                        itemTwo.isChecked = true;
                      }
                    });
                  }
                });
              }
              // item.subList = subData.subList || []
              this.$set(item, "subList", subData.subList);
            });
          });
        }
        this.shopForm = Object.assign({}, { detailList: data.shopList });
        // this.$set(this.shopForm, 'detailList', newShopList)
      }
    },
    chooseSubFun(index) {
      // 选择子账号
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      let subListItem = shopForm.detailList[index].subList || [];
      let num = 0;
      subListItem.map((item) => {
        if (item.isChecked) {
          num++;
        }
      });
      shopForm.detailList[index].agreeSubNum = num;
      this.shopForm = shopForm;
    },
    saveAssignOrder(formName) {
      // 保存派单
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 发送请求
          let shopForm = JSON.parse(JSON.stringify(this.shopForm));
          shopForm.stepNum = 1;
          let subArr;
          shopForm.detailList.map((item) => {
            subArr = [];
            if (item.subList && item.subList.length) {
              item.subList.map((itemTwo) => {
                if (itemTwo.isChecked) {
                  subArr.push(itemTwo.subAccountId);
                }
              });
            }
            item.agreeSubs = subArr.join(",");
          });
          if (shopForm.subAccountList) {
            delete shopForm.subAccountList;
          }
          if (shopForm.subList) {
            delete shopForm.subList;
          }
          if (!this.$route.query.isEdit && shopForm.disOrderNo) {
            delete shopForm.disOrderNo;
          } else if (this.$route.query.isEdit) {
            shopForm.disOrderNo = this.$route.query.id;
          }
          let { data } = await saveAssignOrder(shopForm);
          // let url = `/assign-order/assign-list/assign-two?id=${data}${this.$route.query.isEdit ? ('&isEdit=' + this.$route.query.isEdit) : ''}`
          this.$router.push(
            `/assign-order/assign-list/assign-two?id=${data}${
              this.$route.query.isEdit
                ? "&isEdit=" + this.$route.query.isEdit
                : ""
            }`
          );
        } else {
          this.$message.error("请填写完整的信息");
        }
      });
    },
    deleteShopItem(index) {
      // 删除一条店铺
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      if (shopForm.detailList && shopForm.detailList.length <= 1) {
        return this.$message.error("至少保留一个店铺");
      }
      shopForm.detailList = shopForm.detailList.filter((item, i) => {
        return i != index;
      });
      this.shopForm = shopForm;
    },
    addShopItem() {
      // 添加一条店铺
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      shopForm.detailList.push({ shopName: "", checkbox: [] });
      this.shopForm = shopForm;
    },
    async subLoad(shopId) {
      // 新增子账号-刷新子账号列表
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      let subChooseArr = shopForm.detailList[this.editShopIndex].subList;
      let { data } = await assignShopSub({ shopId });
      if (data.subList && data.subList.length) {
        data.subList.map((item) => {
          item.isChecked = false;
          if (subChooseArr && subChooseArr.length) {
            subChooseArr.map((itemTwo) => {
              if (
                item.subAccountId == itemTwo.subAccountId &&
                itemTwo.isChecked
              ) {
                item.isChecked = true;
              }
            });
          }
        });
      }
      shopForm.detailList[this.editShopIndex].subList = data.subList || [];
      this.shopForm = shopForm;
    },
    async assignShopSub(shopId) {
      // 查询子账号
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      let { data } = await assignShopSub({ shopId });
      if (data.subList && data.subList.length) {
        data.subList.map((item) => {
          item.isChecked = false;
        });
      }
      shopForm.detailList[this.editShopIndex].subList = data.subList || [];
      this.shopForm = shopForm;
    },
    resetShop(index) {
      // 重置当前店铺
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      shopForm.detailList[index] = { shopName: "", checkbox: [] };
      this.shopForm = shopForm;
    },
    chooseShop(row) {
      // 点击表格选中店铺
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      let isRepeat = false;
      if (shopForm && shopForm.detailList && shopForm.detailList.length) {
        shopForm.detailList.map((item) => {
          if (item.shopId == row.shopId) {
            this.$message.error("不可选择重复店铺");
            isRepeat = true;
          }
        });
      }
      if (!isRepeat) {
        // let shopRow = {...row, checkbox: shopForm.detailList[this.editShopIndex].checkbox}
        let shopRow = Object.assign(
          shopForm.detailList[this.editShopIndex],
          row
        );
        shopForm.detailList[this.editShopIndex] = shopRow;
        this.shopForm = shopForm;
        this.assignShopSub(row.shopId);
        this.shopVisible = false;
      }
    },
    contentFun(val, index) {
      // 服务内容
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      shopForm.detailList[index].serviceContent = val.join(",");
      this.shopForm = shopForm;
    },
    workTimeFun(val, index) {
      // 协议客服工作时长
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      shopForm.detailList[index].serviceWorkTime = RangeTimeNum(val[0], val[1]);
      shopForm.detailList[index].agreeStartTime = val[0];
      shopForm.detailList[index].agreeEndTime = val[1];
      this.shopForm = shopForm;
    },
    serviceDateFun(val, index) {
      // 服务时间
      let shopForm = JSON.parse(JSON.stringify(this.shopForm));
      shopForm.detailList[index].shopServiceDay = RangeDateNum(val[0], val[1]);
      shopForm.detailList[index].startTime = DateTransform(val[0]);
      shopForm.detailList[index].endTime = DateTransform(val[1]);
      this.shopForm = shopForm;
    },
    async assignShopSelect(shopName, index) {
      // 查询店铺下拉-index当前编辑的店铺下拉
      this.editShopIndex = index;
      this.loading = true;
      let { data, code } = await assignShopSelect({ shopName });
      this.loading = false;
      if (code == 200) {
        this.shopTableData = data;
        this.shopVisible = true;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.assignOne-container {
  text-align: left;
  position: relative;
  background: #fff;
  padding-bottom: 84px;
  .common-screen-btn {
    height: 30px;
    line-height: 30px;
  }
  .delete-btn {
    margin-left: 80px;
  }
  .addShop {
    height: 84px;
    line-height: 84px;
    text-indent: 88px;
    font-size: 14px;
    span {
      color: #1890ff;
      cursor: pointer;
    }
  }
  .sub-List {
    border-radius: 4px;
    border: 1px solid #eaeaeb;
    max-height: 352px;
    position: relative;
    width: 100%;
    .table-fontsize {
      font-size: 14px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .isWarp {
      white-space: pre-line;
      line-height: 21px;
    }
    .subList-header {
      height: 50px;
      width: 100%;
      background: #f2f2f2;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .subList-content {
      margin-top: 50px;
      max-height: 300px;
      overflow-y: scroll;
      width: 100%;
      .subList-item {
        border-bottom: solid 1px #eaeaeb;
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }
  }
  .input-width {
    width: 80px;
    margin-right: 10px;
  }
  .price-input-width {
    width: 200px;
    margin-right: 10px;
  }
  .shop-item {
    .shopItem-form {
      display: flex;
      .leftRight {
        width: 42%;
        padding: 0 120px 0 60px;
        margin: 32px 0 32px;
        box-sizing: border-box;
        &:nth-of-type(2) {
          border-left: 1px solid #1890ff;
          padding: 0 0 0 80px;
          width: 55%;
        }
      }
    }
  }
  .footer-container {
    height: 100px;
    width: 100%;
    // box-sizing: border-box;
    padding: 0 16px;
    background: #fff;
    position: absolute;
    bottom: -16px;
    right: -16px;
    z-index: 9999;
    .footer-content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      height: 84px;
      align-items: center;
      box-sizing: border-box;
      padding-right: 120px;
      padding-left: 100px;
      .back-btn {
        height: 38px;
        border: 1px solid #1890ff;
        color: #1890ff;
        margin-right: 16px;
      }
      .next-btn {
        height: 38px;
      }
    }
  }
}
</style>
